import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { Component, ElementRef, NgZone, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Language } from '@localization-lib/language/models/Language'
import { OverlayContentRef } from '@shared-ui-lib/overlay/overlay-content-ref'
import { BreakpointService } from '@shared-util-lib/breakpoint/breakpoint.service'
import { ParallaxService } from '@shared-util-lib/parallax/parallax.service'
import { Subscription } from 'rxjs'
import { LandingPageMobileMenuComponent } from './mobile-menu/landing-page-mobile-menu.component'

@Component({
    selector: 'app-landing-page-top-bar-v1',
    templateUrl: './landing-page-top-bar.component.html',
    styleUrls: ['./landing-page-top-bar.component.scss'],
})
export class LandingPageTopBarV1Component implements OnInit, OnDestroy {
    Language = Language
    private subscriptions = new Subscription()
    private destroyParallax?: () => void

    private _overlayRef: OverlayRef | null = null
    private _mobileMenuOverlayRef: OverlayContentRef<LandingPageMobileMenuComponent> | null =
        null

    isScrolled = false
    isScrolledToSticky = false
    mobileMenuOpen = false

    constructor(
        private ngZone: NgZone,
        private overlay: Overlay,
        private router: Router,
        private breakpointService: BreakpointService,
        private elementRef: ElementRef,
        private parallaxService: ParallaxService
    ) {
        this.subscriptions.add(
            this.breakpointService
                .breakpoint$('md')
                .subscribe((isMd) => isMd && this.closeMobileMenu())
        )
    }

    ngOnInit() {
        this.destroyParallax = this.parallaxService.create({
            from: 0,
            to: 64,
            direct: this.elementRef.nativeElement,
            props: {
                '--transition': {
                    from: '0',
                    to: '1',
                },
            },
            inside: (_, percentage) => {
                this.ngZone.run(() => {
                    this.isScrolled = percentage > 0
                    this.isScrolledToSticky && (this.isScrolledToSticky = false)
                })
            },
            outside: (_, percentage) => {
                this.ngZone.run(() => {
                    this.isScrolled = true
                    !this.isScrolledToSticky && (this.isScrolledToSticky = true)
                })
            },
        })
    }

    ngOnDestroy() {
        this.destroyParallax && this.destroyParallax()
        this.subscriptions.unsubscribe()
    }

    signIn() {
        this.router.navigate(['/dashboard'])
    }

    goToRegister() {
        this.router.navigate(['/register'])
    }

    private get overlayConfig(): OverlayConfig {
        return new OverlayConfig({
            height: 'calc(100% - 64px)',
            width: '100vw',
            positionStrategy: this.overlay.position().global().left().bottom(),
            scrollStrategy:
                this.parallaxService.scrollStrategies.blockAndPreserveParallax(),
        })
    }

    openMobileMenu() {
        this.mobileMenuOpen = true

        this._overlayRef = this.overlay.create(this.overlayConfig)
        this._mobileMenuOverlayRef = new OverlayContentRef(this._overlayRef)
        const mobileMenuPortal = new ComponentPortal(
            LandingPageMobileMenuComponent
        )

        const mobileMenuPortalRef = this._overlayRef.attach(mobileMenuPortal)
        this._overlayRef
            .backdropClick()
            .subscribe((_) => this.closeMobileMenu())

        this._mobileMenuOverlayRef.componentInstance =
            mobileMenuPortalRef.instance

        mobileMenuPortalRef.instance.signUp.subscribe((_) => {
            this.closeMobileMenu()
            this.goToRegister()
        })
    }

    closeMobileMenu() {
        this._overlayRef = null
        this._mobileMenuOverlayRef?.close()
        this._mobileMenuOverlayRef = null
        this.mobileMenuOpen = false
    }

    toggleMobileMenu() {
        this._mobileMenuOverlayRef
            ? this.closeMobileMenu()
            : this.openMobileMenu()
    }
}
