import { OverlayModule } from '@angular/cdk/overlay'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { MatTooltipModule } from '@angular/material/tooltip'
import { GroupManagementDropdownModule } from '@group-management-lib/components/group-management-dropdown/group-management-dropdown.module'
import { GroupManagementHeaderComponent } from '@group-management-lib/components/group-management-header/group-management-header.component'
import { LoadingModule } from '@loading-lib/loading.module'
import { TranslationModule } from '@localization-lib/language/translation.module'
import { MatCheckbox } from '@angular/material/checkbox'

@NgModule({
    declarations: [GroupManagementHeaderComponent],
    exports: [GroupManagementHeaderComponent],
    imports: [
        CommonModule,
        GroupManagementDropdownModule,
        LoadingModule,
        MatIconModule,
        MatTooltipModule,
        OverlayModule,
        TranslationModule,
        MatCheckbox,
    ],
})
export class GroupManagementHeaderModule {}
