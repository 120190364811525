import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot } from '@angular/router'
import { AuthenticationState, AuthnService } from '@auth-util-lib/authn.service'
import { LandingPageFooterV1Component } from '@platform-lib/components/footer/landing-page-footer/v1/landing-page-footer.component'
import { of } from 'rxjs'
import { map, startWith } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class LandingPageFooterResolver {
    constructor(private authn: AuthnService) {}

    resolve(route: ActivatedRouteSnapshot) {
        return of(
            this.authn.stateChanges().pipe(
                startWith(AuthenticationState.Unauthenticated),
                map((authState) => {
                    if (authState === AuthenticationState.Authenticated) {
                        return undefined
                    }

                    return LandingPageFooterV1Component
                })
            )
        )
    }
}
