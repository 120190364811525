export enum AnalyticsCategory {
    AccountSettings = 'Account settings',
    Booking = 'Booking',
    ConditionMonitoringLimitDialog = 'Condition Monitoring Limit Dialog',
    ConnectInfoPage = 'Connect info page',
    ContractManagement = 'Contract management',
    Dashboard = 'Dashboard',
    DispatchManagement = 'Dispatch management',
    ETA = 'ETA',
    FastTrack = 'FastTrack',
    Feedback = 'Feedback',
    FeedbackPopup = 'Feedback Popup',
    FilterPanel = 'Filter panel',
    GroupManagement = 'Group management',
    Header = 'Header',
    IdleTimes = 'Idle times',
    Navigation = 'Navigation',
    Notification = 'Notification',
    NotificationEmailSettings = 'Notification Email Settings',
    NotificationPlatformSettings = 'Notification Platform Settings',
    Onboarding = 'Onboarding',
    OnboardingBooking = 'Onboarding - Booking',
    OnboardingCancellation = 'Onboarding - Cancellation',
    OnboardingConfirmation = 'Onboarding - Confirmation',
    OnboardingEdit = 'Onboarding - Edit',
    OnboardingReminder = 'Onboarding - Reminder',
    OnboardingWelcome = 'Onboarding - Welcome',
    OnboardingFeedback = 'Onboarding - Feedback',
    Page = 'Page',
    Pathfinder = 'Pathfinder',
    SelectionJourney = 'Selection journey',
    Sidebar = 'Sidebar',
    Table = 'Table',
    TableFilter = 'Table filter',
    Banner = 'Banner',
    UserManagement = 'User management',
    WagonDetails = 'Wagon details',
    Component = 'Component',
}

export enum AnalyticsTableAction {
    ResetTable = 'Reset table',
    ResetFilter = 'Reset filter',
    FollowLink = 'Follow link',
    ChangePage = 'Change page',
    ChangePageSize = 'Change page size',
    SortColumn = 'Sort column',
    SortAsc = 'Sort ascending',
    SortDesc = 'Sort descending',
    OpenFilter = 'Open filter',
    PreviousPage = 'Previous page',
    NextPage = 'Next page',
    FirstPage = 'First page',
    LastPage = 'Last page',
    ExportData = 'Export data',
}

export enum AnalyticsTableFilterAction {
    SearchCommissionNumber = 'Search commission number',
    SearchContract = 'Select contract',
    SearchWagonNumber = 'Search wagon number',
    SelectDateRange = 'Select date range',
    SelectFieldOrOption = 'Select field or option',
    SelectIdleTimes = 'Select idle times',
    SelectLoad = 'Select load',
    SelectLoadingState = 'Select loading state',
    SelectLocation = 'Select location',
    SelectProduct = 'Select product',
    SelectRentalStates = 'Select rental state',
    SelectTimeFrame = 'Select time frame',
    SelectUICCategoryLetter = 'Select UIC category letter',
    SelectUICClass = 'Select UIC class',
    SelectWagonPool = 'Select wagon pool',
    ToggleTableColumnVisibility = 'Toggle table column visibility',
    ResetFilter = 'Reset filter',
    ApplyFilter = 'Apply filter',
    SelectGroup = 'Select group',
    SelectValue = 'Select value',
    SearchInput = 'Search input',
    SelectRange = 'Select range',
    SelectDateFilterOption = 'Select date filter option',
    SelectDate = 'Select Date',
}

export enum AnalyticsDashboardAction {
    ClickWidget = 'Click widget',
    ClickQuickLink = 'Click quick link',
    ClickQuickFilter = 'Click quick filter',
    FollowMileagePredictionBanner = 'Follow mileage prediction banner',
    CloseMileagePredictionBanner = 'Close mileage prediction banner',
    EnterWidgetMoveMode = 'Enter widget move mode',
    LeaveWidgetMoveMode = 'Leave widget move mode',
    ClickNewsWidgetButton = 'Click news widget button',
}

export enum AnalyticsSidebarAction {
    ClickEntry = 'Click entry',
}

export enum AnalyticsHeaderAction {
    OpenUserMenu = 'Open user menu',
    OpenAccountSettings = 'Open account settings',
    Logout = 'Logout',
    LogoClick = 'Logo click',
}

export enum AnalyticsNotificationAction {
    ClickBell = 'Click notification bell',
    ClickCockpitLink = 'Click cockpit link',
    ClickBookmarkOn = 'Click bookmark ON',
    ClickBookmarkOff = 'Click bookmark OFF',
    ClickNotification = 'Click notification',
    ClickRemoveNotification = 'Click remove notification',
    PageClickLoadMore = 'Page - Click load more',
    PageClickMarkedFilterOn = 'Page - Click marked filter ON',
    PageClickMarkedFilterOff = 'Page - Click marked filter OFF',
    PageClickPriorityFilterOn = 'Page - Click priority filter ON',
    PageClickPriorityFilterOff = 'Page - Click priority filter OFF',
    PageClickUseCaseFilterOn = 'Page - Click use case filter ON',
    PageClickUseCaseFilterOff = 'Page - Click use case filter OFF',
    PanelClickHeadline = 'Panel - Click panel headline',
    PanelClickShowAll = 'Panel - Click show all',
}

export enum AnalyticsNotificationSettingsAction {
    ClickEnableAllOn = 'Click enable all ON',
    ClickEnableAllOff = 'Click enable all OFF',
    ClickInterval = 'Click interval',
    ClickUseCaseOn = 'Click enable all ON',
    ClickUseCaseOff = 'Click enable all OFF',
}

export enum AnalyticsConditionLimitDialogAction {
    OpenDialog = 'Open dialog',
    Save = 'Save',
    Reset = 'Reset',
}

export enum AnalyticsAccountSettingsAction {
    GoToServiceDesk = 'Go to service desk',
    ChangePassword = 'Change password',
    ChangeLanguage = 'Change language',
}

export enum AnalyticsFilterAction {
    ShowInTable = 'Show in table',
    ShowInTableDetail = 'Show in table detail level',
    FilterTimePeriod = 'Filter time period',
    FilterLoad = 'Filter load',
    FilterGraph = 'Filter via graph',
}

export enum AnalyticsPathfinderAction {
    BackButtonClick = 'Back button click',
    ClusterClick = 'Cluster click',
    SelectWagonFromCluster = 'Select wagon from cluster',
    SelectWagonFromSearchResult = 'Select wagon from search result',
    SelectWagonFromEvent = 'Select wagon from event',
    FilterButtonClick = 'Filter button click',
    FilterChange = 'Filter change',
    GeofenceCreateButtonClick = 'Create Geofence click',
    GeofenceLeaveGeofenceModeClick = 'Leave Geofence mode click',
    GeofenceOpenOverview = 'Open geofences overview',
    GeofenceOverviewEdit = 'Geofence overview edit click',
    GeofenceOverviewNew = 'Geofence overview new geofence click',
    GeofenceUseTemplateClick = 'Use template click',
    GeofenceEditorCreateButtonClick = 'Geofence editor create click',
    GeofenceEditorCancelButtonClick = 'Geofence editor cancel click',
    GeofenceEditorUseTemplateButtonClick = 'Geofence editor use template click',
    JourneyClearDateRange = 'Journey clear date range',
    JourneySetDateRangeStart = 'Journey set date range start',
    JourneySetDateRangeFinish = 'Journey set date range finish',
    SidePanelClose = 'Side panel close',
    SidePanelOpen = 'Side panel open',
    ZoomInClick = 'Zoom in click',
    ZoomOutClick = 'Zoom out click',
    WagonClick = 'Wagon click',
    WagonDetailPageButtonClick = 'Wagon detail page button click',
    EventsSetDateRangeStart = 'Event set date range start',
    EventsSetDateRangeEnd = 'Event set date range finish',
    EventsClearDateRange = 'Event clear date range',
}

export enum AnalyticsIdleTimesAction {
    IdleTimesBannerButtonClick = 'Idle Times Banner Button Click',
}

export enum AnalyticsConnectInfoPageAction {
    OpenWhatPanel = 'Open "What is VTG Connect" panel',
    OpenHowPanel = 'Open "How do I get VTG Connect" panel',
    GoToVTG = 'Go to vtg.com',
    FollowIntriguedLink = 'Follow "Intrigued yet?" link',
    SendRequest = 'Send request',
}

export enum AnalyticsUserManagementAction {
    Reset = 'Reset',
    Save = 'Save',
    CreateNewUser = 'Create new user',
    CancelNewUserCreation = 'Cancel new user creation',
    SubmitNewUser = 'Submit new user',
    DialogQuitCreating = 'Dialog - quit creating new user layer',
    DialogContinueCreating = 'Dialog - continue creating layer',
    DialogQuitEditing = 'Dialog - quit editing user layer',
    DialogContinueEditing = 'Dialog - continue editing layer',
    DialogCancelDelete = 'Dialog - cancel deleting user',
    DialogDeleteUser = 'Dialog - delete user',
    CreatorUser = 'Creator - create user',
    CreatorClose = 'Creator - close dialog',
    EditorUser = 'Editor - edit user',
    EditorClose = 'Editor - close dialog',
    EditorDeleteUser = 'Editor - delete user',
    GoToServiceDesk = 'Go to service desk',
}

export enum AnalyticsETAAction {
    SubmitForm = 'Submit form',
    ResetForm = 'Reset form',
}

export enum AnalyticsContractManagementAction {
    ExportReport = 'Export report',
    ChangeContractName = 'Change contract name',
    DownloadDocument = 'Download document',
    ToggleSection = 'Toggle section',
    QuickFilterIssues = 'Quick filter - contracts with issues',
    QuickFilterHints = 'Quick filter - contracts with hints',
    QuickFilterRentalState = 'Quick filter - rental state',
}

export enum AnalyticsFastTrackAction {
    ChangeTab = 'Change tab',
    SelectLocation = 'Select location',
    ChangeProduct = 'Change product',
    ViewTrainSpecificDetails = 'View train-specific details',
    ViewLocationSpecificDetails = 'View location specific details',
    DownloadTrainConfiguration = 'Download train configuration',
    DownloadTechnicalDetails = 'Download technical details',
    DownloadLocationSpecificInstructions = 'Download location specific instructions',
    DownloadFastTrackTermsAndConditions = 'Download FastTrack terms and conditions',
    DownloadVTGTermsAndConditions = 'Download VTG terms and conditions',
    GoToInfrastructureManager = 'Go to infrastructure manager',
    OpenAdminArea = 'Open admin area',
    EditLocation = 'Edit location',
    AddProduct = 'Add product',
    EditProduct = 'Edit product',
    PublishProduct = 'Publish product',
    DeleteProductSanityCheck = 'Open delete product sanity check',
    CancelDeleteProduct = 'Cancel delete product',
    DeleteProduct = 'Delete product',
    GoToLocationOverview = 'Go to location overview',
    GoToFastTrackLandingPage = 'Go to fast track landing page',
    GoToProductEditor = 'Go to product editor',
    GoToServiceDesk = 'Go to service desk',
    GoToBookings = 'Go to booking overview',
    GoToPrivacyPolicy = 'Go to privacy policy',
    CancelProduct = 'Cancel product save',
    SubmitBookingRequest = 'Submit booking request',
    AcceptAll = 'Accept all',
    SelectDate = 'Select date',
    ClickProceedToBooking = 'Proceed to booking',
    RetryBookingRequest = 'Retry booking request',
    ResetFilter = 'Reset filter',
    FilterByWagonType = 'Filter by wagon type',
    FilterByLocationName = 'Filter by location name',
    FilterByPeriod = 'Filter by period',
}

export enum AnalyticsBannerAction {
    FollowBanner = 'Follow banner',
    HideBanner = 'Hide banner',
    ClickLink = 'Click link',
}

export enum AnalyticsSelectionJourneyAction {
    ClearSelection = 'Clear selection',
    CheckSelection = 'Check selection',
    ConfirmSelection = 'Confirm selection',
    AddEntry = 'Add entry',
    UpdateEntry = 'Update entry',
    RemoveEntry = 'Remove entry',
    SelectFieldOrOption = 'Select field or option',
}

export enum AnalyticsNavigationAction {
    NavigateTo = 'Navigate to',
}

export enum AnalyticsFollowLinkLabel {
    WagonDetail = 'Wagon Detail',
    WagonDetailMileage = 'Wagon Detail - Mileage',
    WagonDetailConditionMonitoring = 'Wagon Detail - Condition Monitoring',
    MileageOverview = 'Mileage Overview',
    ContractDetails = 'Contract Details',
}

export enum BookingAction {
    PlayVideo = 'Play Video',
    ExpandUseCaseDescription = 'Expand Use Case Description',
}

export enum FeedbackAction {
    OpenFeedbackForm = 'Open feedback form',
    SendFeedbackForm = 'Send feedback form',
    CloseFeedbackForm = 'Close feedback form',
}

export enum FeedbackPopupAction {
    DisplayFeedbackPopup = 'Display feedback popup',
    SendFeedbackForm = 'Send feedback via popup',
    CloseFeedbackForm = 'Close feedback popup',
}

export enum OnboardingAction {
    BookingClick = 'Booking Click',
    CancelClick = 'Cancel Click',
    FeedbackClick = 'Feedback Click',
    DateClick = 'Date Click',
    UpdateClick = 'Update Click',
    OpenFeedbackForm = 'Open Feedback Form',
    OpenCancelationForm = 'Open Cancellation Form',
    OpenBookingForm = 'Open Booking Form',
    OpenEditForm = 'Open Edit Form',
    TimeSlotClick = 'Time Slot Click',
    SendFeedbackClick = 'Send feedback click',
    KeepAppointmentClick = 'Keep appointment click',
}

export enum DispatchAction {
    ClickWidget = 'Click widget',
    ChangeStation = 'Change station',
    ReloadWidget = 'Reload widget',
    RemoveStation = 'Remove station',
    AddStation = 'Add station',
    SelectStation = 'Select station',
    ToggleStation = 'Toggle station',
    ChangeTab = 'Change tab',
    ShowInTable = 'Show in table',
    ClickButton = 'Click button',
    DownloadDocument = 'Download document',
}

export enum WagonDetailsAction {
    ReloadPage = 'Reload page',
    ChangeTab = 'Change tab',
    AddMileageYear = 'Add mileage year',
    SubstractMileageYear = 'Substract mileage year',
    FollowLink = 'Follow link',
    AddMaintenanceYear = 'Add maintenance year',
    SubstractMaintenanceYear = 'Substract maintenance year',
    ReloadSensorData = 'Reload sensor data',
    ExpandSensorData = 'Expand sensor data',
    CollapseSensorData = 'Collapse sensor data',
    OperationalDocumentQuickFilterClick = 'Operational document quick filter click',
    OperationalDocumentDownloadClick = 'Operational document download click',
    PowerBoxPlugBarHover = 'PowerBox plug bar hover',
    PowerBoxOperatingStatusHover = 'PowerBox operating status hover',
    SensorDataPeriodPicker = 'Sensor data period picker',
}

export enum AnalyticsPageAction {
    OnLoad = 'on load',
    PreviousPage = 'Previous page',
    NextPage = 'Next page',
    FirstPage = 'First page',
    LastPage = 'Last page',
}

export enum AnalyticsCounterAction {
    Subtract = 'Subtract',
    Input = 'Input',
    Add = 'Add',
}

export enum AnalyticsTimePickerAction {
    Decrement = 'Decrement',
    Increment = 'Increment',
    Input = 'Input',
}

export enum AnalyticsGroupManagementAction {
    ToggleDropdown = 'Toggle dropdown',
    DropdownSettings = 'Dropdown - open settings',
    DropdownCreateGroup = 'Dropdown - create new group',
    DropdownReset = 'Dropdown - reset',
    DropdownApply = 'Dropdown - apply changes',
    DropdownToggleGroup = 'Dropdown - toggle group',
    DropdownEditGroup = 'Dropdown - edit group',
    DropdownClose = 'Dropdown - close',
    CreatorSubmit = 'Creator - create group',
    CreatorClose = 'Creator - close dialog',
    EditorDeleteGroup = 'Editor - delete group',
    EditorSaveGroup = 'Editor - save group',
    UseCaseLearnMore = 'UseCase - learn more',
    UseCaseCreateGroup = 'UseCase - create new group',
    UseCaseEditGroup = 'UseCase - edit group',
    DialogQuitCreating = 'Dialog - quit creating new group layer',
    DialogContinueCreating = 'Dialog - continue creating layer',
    DialogQuitEditing = 'Dialog - quit editing group layer',
    DialogContinueEditing = 'Dialog - continue editing layer',
    DialogCancelDelete = 'Dialog - cancel deleting group',
    DialogDeleteGroup = 'Dialog - delete group',
    EditClose = 'Edit - close nonModal new group Layer',
}

export type AnalyticsEvent = (
    | {
          category: AnalyticsCategory.Table
          action: AnalyticsTableAction
      }
    | {
          category: AnalyticsCategory.TableFilter
          action: AnalyticsTableFilterAction
      }
    | {
          category: AnalyticsCategory.Dashboard
          action: AnalyticsDashboardAction
      }
    | {
          category: AnalyticsCategory.Sidebar
          action: AnalyticsSidebarAction
      }
    | {
          category: AnalyticsCategory.Header
          action: AnalyticsHeaderAction
      }
    | {
          category: AnalyticsCategory.Notification
          action: AnalyticsNotificationAction
      }
    | {
          category: AnalyticsCategory.NotificationEmailSettings
          action: AnalyticsNotificationSettingsAction
      }
    | {
          category: AnalyticsCategory.NotificationPlatformSettings
          action: AnalyticsNotificationSettingsAction
      }
    | {
          category: AnalyticsCategory.FilterPanel
          action: AnalyticsFilterAction
      }
    | {
          category: AnalyticsCategory.ConditionMonitoringLimitDialog
          action: AnalyticsConditionLimitDialogAction
      }
    | {
          category: AnalyticsCategory.AccountSettings
          action: AnalyticsAccountSettingsAction
      }
    | {
          category: AnalyticsCategory.Pathfinder
          action: AnalyticsPathfinderAction
      }
    | {
          category: AnalyticsCategory.IdleTimes
          action: AnalyticsIdleTimesAction
      }
    | {
          category: AnalyticsCategory.ConnectInfoPage
          action: AnalyticsConnectInfoPageAction
      }
    | {
          category: AnalyticsCategory.UserManagement
          action: AnalyticsUserManagementAction
      }
    | {
          category: AnalyticsCategory.ETA
          action: AnalyticsETAAction
      }
    | {
          category: AnalyticsCategory.ContractManagement
          action: AnalyticsContractManagementAction
      }
    | {
          category: AnalyticsCategory.FastTrack
          action: AnalyticsFastTrackAction
      }
    | {
          category: AnalyticsCategory.Banner
          action: AnalyticsBannerAction
      }
    | {
          category: AnalyticsCategory.SelectionJourney
          action: AnalyticsSelectionJourneyAction
      }
    | {
          category: AnalyticsCategory.Navigation
          action: AnalyticsNavigationAction
      }
    | {
          category: AnalyticsCategory.Booking
          action: BookingAction
      }
    | {
          category: AnalyticsCategory.Feedback
          action: FeedbackAction
      }
    | {
          category: AnalyticsCategory.FeedbackPopup
          action: FeedbackPopupAction
      }
    | {
          category: AnalyticsCategory.Onboarding
          action: OnboardingAction
      }
    | {
          category: AnalyticsCategory.OnboardingWelcome
          action: OnboardingAction
      }
    | {
          category: AnalyticsCategory.OnboardingBooking
          action: OnboardingAction
      }
    | {
          category: AnalyticsCategory.OnboardingEdit
          action: OnboardingAction
      }
    | {
          category: AnalyticsCategory.OnboardingConfirmation
          action: OnboardingAction
      }
    | {
          category: AnalyticsCategory.OnboardingFeedback
          action: OnboardingAction
      }
    | {
          category: AnalyticsCategory.OnboardingReminder
          action: OnboardingAction
      }
    | {
          category: AnalyticsCategory.OnboardingCancellation
          action: OnboardingAction
      }
    | {
          category: AnalyticsCategory.DispatchManagement
          action: DispatchAction
      }
    | {
          category: AnalyticsCategory.WagonDetails
          action: WagonDetailsAction
      }
    | { category: AnalyticsCategory.Component; action: AnalyticsPageAction }
    | { category: AnalyticsCategory.Component; action: AnalyticsCounterAction }
    | {
          category: AnalyticsCategory.Component
          action: AnalyticsTimePickerAction
      }
    | { category: AnalyticsCategory.Page; action: AnalyticsPageAction }
    | {
          category: AnalyticsCategory.GroupManagement
          action: AnalyticsGroupManagementAction
      }
) & {
    label?: string
    value?: number
}
